<template>
	<div class="footer">
		<div class="footer__container pb-0 pt-5">
			<img src="https://firebasestorage.googleapis.com/v0/b/medean95-4d3b4.appspot.com/o/m-light2.png?alt=media&token=07ecbf75-ad94-41c6-8512-dcf19a7de500" alt="" class="logo" />
			<p><strong>Medea Inc. dba Medea Medical Products</strong></p>
			<!-- <p>California Certified Small Business</p> -->
  		<p>State of California Contract Number: <a href="https://www.caleprocure.ca.gov/PSRelay/ZZ_PO.ZZ_CTR_SUP_CMP.GBL?Page=ZZ_CTR_SUP_PG&Action=U&SETID=STATE&CNTRCT_ID=1-20-65-32S" target="_blank">1-20-65-32S</a></p>
  		<p>Office Phone: (925) 425-9282</p>
  		<p>Fax: 866-336-8969</p>
  		<br>
  		<address class="mb-3">
  			Medea Medical Products<br>
  			5653 Stoneridge Drive, Suite 119 <br>
  			Pleasanton, CA 94588
  		</address>
  		<p>
        <a href="https://firebasestorage.googleapis.com/v0/b/medeamp-deeef.appspot.com/o/CertificateofAppointment.pdf?alt=media&token=e0f2abd6-3655-441a-87ca-50978506926f" target="_blank">Honeywell Certificate of Appointment</a> / 
  			<router-link :to="{name: 'privacy'}">Privacy Policy</router-link> / <router-link :to="{name: 'terms'}">Terms of Use</router-link>
  		</p>
		</div>
		<div class="footer__container text-center">
			<p class="caption">© Medea Inc, 2021</p>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
}
</script>